<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import NetworkStatus from "@/components/NetworkStatus.vue";
import Loader from "@/components/Loader.vue";
import FixedMenu from "./components/FixedMenu.vue";

const isLoading = ref(false);
const router = useRouter();

// Activate the loader when entering any path
router.beforeEach((to, from, next) => {
  isLoading.value = true;
  next();
});

// Disable loader after loading each component
router.afterEach(() => {
  isLoading.value = false;
});
</script> 

<template>
  <NetworkStatus />
  <Loader v-if="isLoading" />
  <router-view />
  <FixedMenu />
</template>