import { ref } from "vue";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";

export const useAuthStore = defineStore("Auth", () => {
  const router = useRouter();
  const isAuthenticated = ref(false);

  let accessToken = localStorage.getItem("accessToken") || null;
  let refreshToken = localStorage.getItem("refreshToken") || null;

  const setTokens = ({ newAccessToken, newRefreshToken }) => {
    accessToken = newAccessToken;
    refreshToken = newRefreshToken;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    isAuthenticated.value = true;
  };

  const clearTokens = () => {
    accessToken = null;
    refreshToken = null;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("personInfo");
    isAuthenticated.value = false;
  };

  const checkTokenValidity = () => {
    // const accessToken = getAccessToken();
    if (!accessToken) {
      clearTokens();
      isAuthenticated.value = false;
    } else {
      try {
        const payload = JSON.parse(atob(accessToken.split(".")[1]));
        const exp = payload.exp * 1000;
        if (Date.now() >= exp) {
          clearTokens();
          router.push("/login");
        } else {
          isAuthenticated.value = true;
        }
      } catch (error) {
        clearTokens();
        router.push("/login");
      }
    }
  };

  checkTokenValidity();

  window.addEventListener("storage", (event) => {
    if (event.key === "accessToken") {
      accessToken = localStorage.getItem("accessToken");
      refreshToken = localStorage.getItem("refreshToken");
      checkTokenValidity();
    }
  });

  // const refreshAccessToken = async () => {
  //   if (!refreshToken) return;
  //   try {
  //     const response = await axios.post("/api/refresh", { refreshToken });
  //     const newTokens = {
  //       newAccessToken: response.data.accessToken,
  //       newRefreshToken: response.data.refreshToken,
  //     };
  //     setTokens(newTokens);
  //   } catch (error) {
  //     console.error("Refresh token failed:", error);
  //     clearTokens()
  //   }
  // };

  return { setTokens, clearTokens, isAuthenticated };
});
